import styled, { css } from 'styled-components';

import { icons, mediaQuery, styles, utils } from '@pav-apps/design-system';

export const SummaryHeadingIcon = styled(icons.PhoneContact)`
  height: 112px;
  width: 112px;

  fill: ${styles.newColors.primary[500]};

  .colorized {
    fill: ${styles.colors.white};
  }

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    height: 136px;
    width: 136px;
  }
`;

export const SummaryHeading = styled.h2`
  font-size: ${utils.fluid(30)};
  font-weight: 800;
  color: ${styles.newColors.grey[500]}; /* IE11 fallback */
  color: var(--primary-text-color);

  margin-top: ${utils.fluid(8, 'em')};
  margin-bottom: 0;

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(38)};
  }
`;

export const SummaryHeadingDescription = styled.p`
  font-size: ${utils.fluid(18)};
  font-weight: 400;
  color: ${styles.newColors.grey[500]}; /* IE11 fallback */
  color: var(--primary-text-color);

  max-width: 920px;
  margin-top: ${utils.fluid(18)};
  margin-bottom: ${utils.fluid(18)};
  padding: 0 ${utils.fluid(16, 'em')};
`;

export const SummaryBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const SummaryBlockHeading = styled.h3`
  font-size: ${utils.fluid(20)};
  font-weight: 700;
  color: ${styles.newColors.grey[500]}; /* IE11 fallback */
  color: var(--primary-text-color);
  text-align: center;

  margin-bottom: ${utils.fluid(16, 'em')};

  ${mediaQuery.greaterThan(1080)} {
    text-align: left;
    margin-bottom: ${utils.fluid(8, 'em')};
  }
`;

export const SummaryReferenceNumberTitle = styled.p`
  font-size: ${utils.fluid(16)};
  font-weight: 300;
  color: ${styles.newColors.grey[500]};
  margin: 0;
`;

export const SummaryReferenceNumber = styled.p`
  font-size: ${utils.fluid(16)};
  font-weight: 600;
  color: ${styles.newColors.grey[500]};
  margin: 0;
`;

export const SummaryPrice = styled.span`
  font-size: ${utils.fluid(55)};
  font-weight: 700;
  color: ${styles.newColors.grey[500]};

  grid-area: price;
  justify-self: flex-end;

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(68)};
  }
`;

export const SummaryPriceSup = styled.sup`
  top: 22px;
  font-size: ${utils.fluid(32)};
  font-weight: 700;
  color: ${styles.newColors.grey[500]};

  grid-area: sup;
  justify-self: flex-start;

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    top: 28px;
    font-size: ${utils.fluid(40)};
  }
`;

export const SummaryTerm = styled.span`
  font-size: ${utils.fluid(18)};
  font-weight: 700;
  color: ${styles.newColors.grey[400]};

  margin-left: 10px;
  margin-bottom: 6px;

  grid-area: term;
  align-self: flex-end;
  justify-self: flex-start;

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(22)};
    margin-left: 12px;
    margin-bottom: 8px;
  }
`;

export const SummaryCaption = css`
  line-height: 1.2;
  color: ${styles.newColors.grey[500]};

  margin: 0;
`;

export const SummaryPriceCaption = styled.p`
  font-size: ${utils.fluid(16)};
  font-weight: 300;
  ${SummaryCaption}
`;

export const SummaryProfileCaption = styled.p`
  font-size: ${utils.fluid(14)};
  font-weight: 400;
  ${SummaryCaption}
`;

export const SummaryValue = styled.p`
  font-size: ${utils.fluid(16)};
  font-weight: 600;
  color: ${styles.newColors.grey[500]};

  word-break: break-word;
  margin: 0;

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    word-break: keep-all;
  }
`;

export const SummaryDescription = styled.p`
  font-size: ${utils.fluid(12)};
  font-weight: 400;
  line-height: 1.5;
  color: ${styles.newColors.grey[500]};

  margin-top: ${utils.fluid(24)};
  margin-bottom: 0;
`;

export const Separator = styled.div`
  height: 1px;
  margin: ${utils.fluid(12, 'em')} 0;
  background-color: ${styles.newColors.grey[300]};
`;

export const Comment = styled.p`
  font-size: ${utils.fluid(14)};
  font-weight: 300;
  color: ${styles.newColors.grey[500]};

  max-width: 500px;
  margin-top: ${utils.fluid(14)};
  margin-bottom: ${utils.fluid(14)};
`;

export const Author = styled.p`
  font-size: ${utils.fluid(15)};
  font-weight: 600;
  color: ${styles.newColors.grey[500]};

  margin-top: ${utils.fluid(14)};
  margin-bottom: ${utils.fluid(14)};
`;
