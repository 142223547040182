export const normalizeNumberToString = (value, number = 1) => {
  let result = value.toString();

  if (result.length < number) {
    result = '0'.repeat(number - result.length) + result;
  }

  return result;
};

export const graphQLVariablesReducer = values => {
  const variables = {};

  if (
    values.dobYear ||
    values.dobMonth ||
    values.dobDay ||
    values.email ||
    values.fullName ||
    values.gender ||
    values.phone ||
    values.postalCode
  ) {
    variables.applicant = {};

    variables.applicant.dob = `${values.dobYear}-${values.dobMonth}-${values.dobDay}T00:00:00Z`;

    if (values.email) variables.applicant.email = values.email;

    if (values.fullName) variables.applicant.fullName = values.fullName;

    if (values.gender) variables.applicant.gender = values.gender;

    if (values.phone) variables.applicant.phone = values.phone;

    if (values.postalCode) variables.applicant.postalCode = values.postalCode;
  }

  if (values.smoking && typeof values.smoking === 'string') {
    variables.healthReport = {};

    if (values.smoking.toLowerCase() === 'true')
      variables.healthReport.smoking = true;
    if (values.smoking.toLowerCase() === 'false')
      variables.healthReport.smoking = false;
  }

  if (
    values.coverageAmount ||
    values.policyLength ||
    values.productSelected ||
    values.paymentMode
  ) {
    variables.quote = {};

    if (values.coverageAmount)
      variables.quote.coverageAmount = values.coverageAmount;

    if (values.policyLength)
      variables.quote.policyTermSelected = values.policyLength;

    if (values.productSelected) {
      variables.quote.policyTermSelected = `${values.productSelected.type}:${values.productSelected.term}`;
      variables.quote.productSelected = values.productSelected.id;
    }

    if (values.paymentMode) variables.quote.paymentMode = values.paymentMode;
  }

  return variables;
};

export const convertYearToPolicyTerm = year => {
  switch (Number(year)) {
    case 10:
      return '0:2';

    case 15:
      return '0:3';

    case 20:
      return '0:4';

    case 25:
      return '0:5';

    case 30:
      return '0:6';

    case 35:
      return '0:7';

    case 40:
      return '0:8';

    default:
      return '0:2';
  }
};

export const convertPolicyTermToYear = policyTerm => {
  switch (policyTerm) {
    case '0:2':
      return 10;

    case '0:3':
      return 15;

    case '0:4':
      return 20;

    case '0:5':
      return 25;

    case '0:6':
      return 30;

    case '0:7':
      return 35;

    case '0:8':
      return 40;

    default:
      return 10;
  }
};

export const marksAmounts = [
  5000,
  10000,
  15000,
  20000,
  30000,
  40000,
  50000,
  75000,
  100000,
  200000,
  300000,
  400000,
  500000,
  600000,
  700000,
  800000,
  900000,
  1000000,
  1250000,
  1500000,
  1750000,
  2000000,
];
