import React from 'react';
import styled from 'styled-components';
import { icons, styles, utils } from '@pav-apps/design-system';

const Container = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
  margin-top: ${utils.fluid(40, 'em')};
  max-width: 210px;
`;

const StyledLockIcon = styled(icons.Lock)`
  height: 48px;
  width: 48px;
  fill: ${styles.colors.primary.default};
`;

const Important = styled.p`
  font-size: ${utils.fluid(20)};
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  color: ${styles.newColors.grey[500]};

  margin-top: ${utils.fluid(10, 'em')};
  margin-bottom: ${utils.fluid(12, 'em')};
`;

const Additional = styled.p`
  font-size: ${utils.fluid(17)};
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: ${styles.newColors.grey[500]};

  margin-top: 0;
  margin-bottom: ${utils.fluid(17, 'em')};
`;

const ApplicationStepConfidential = () => (
  <Container>
    <StyledLockIcon />
    <Important>Vos informations sont confidentielles.</Important>
    <Additional>
      Nous allons l’utiliser pour vous contacter à propos de votre demande
    </Additional>
  </Container>
);

export default ApplicationStepConfidential;
