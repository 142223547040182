// vendors
import React from 'react';
import { useFormikContext } from 'formik';
import {
  Paper,
  FormField,
  FormLabel,
  FormHelperText,
  Input,
  global,
  utils,
} from '@pav-apps/design-system';
import InputMask from 'react-input-mask';
import {} from 'styled-components/macro';
import { css } from 'styled-components';

const leftOffset = css`
  margin-left: ${utils.fluid(22)};
`;

const beforeMaskedStateChange = ({ nextState }) => {
  let { value } = nextState;
  value = value.toUpperCase();

  const phoneMaskRegex = /^(\+)?(1)?[- ]?\(?[_]{3}\)?[- ]?[_]{3}[- ]?[_]{4}$/;
  const postalCodeMaskRegex = /^[_]{3} ?[_]{3}$/;

  if (phoneMaskRegex.test(value) || postalCodeMaskRegex.test(value)) {
    value = '';
  }

  return {
    ...nextState,
    value,
  };
};

const ApplicantStepDetails = () => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormikContext();

  return (
    <Paper padded bordered shadowed>
      <div
        css={`
          display: grid;
          grid-row-gap: ${utils.fluid(14)};
          ${global.fullWidth}
        `}
      >
        <FormField
          css={`
            margin-top: ${utils.fluid(16)};
            ${global.fullWidth}
          `}
        >
          <FormLabel
            htmlFor='fullname'
            css={`
              ${leftOffset}
            `}
          >
            Nom complet
          </FormLabel>

          <Input
            aria-describedby='fullname-helper-text'
            id='fullname'
            name='fullName'
            type='text'
            placeholder='Insérez votre nom'
            value={values.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.fullName && touched.fullName}
            css={`
              ${global.fullWidth}
            `}
          />

          <FormHelperText
            id='fullname-helper-text'
            error={errors.fullName && touched.fullName}
            css={`
              ${leftOffset}
            `}
          >
            {touched.fullName ? errors.fullName : ''}
          </FormHelperText>
        </FormField>

        <FormField
          css={`
            ${global.fullWidth}
          `}
        >
          <FormLabel
            htmlFor='email'
            css={`
              ${leftOffset}
            `}
          >
            Courriel
          </FormLabel>

          <Input
            aria-describedby='email-helper-text'
            id='email'
            name='email'
            type='text'
            placeholder='Insérez votre adresse courriel'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            css={`
              ${global.fullWidth}
            `}
          />

          <FormHelperText
            id='email-helper-text'
            error={errors.email && touched.email}
            css={`
              ${leftOffset}
            `}
          >
            {touched.email ? errors.email : ''}
          </FormHelperText>
        </FormField>

        <FormField
          css={`
            ${global.fullWidth}
          `}
        >
          <FormLabel
            htmlFor='phone'
            css={`
              ${leftOffset}
            `}
          >
            Téléphone
          </FormLabel>

          <InputMask
            aria-describedby='phone-helper-text'
            mask='999-999-9999'
            beforeMaskedStateChange={beforeMaskedStateChange}
            id='phone'
            name='phone'
            type='phone'
            placeholder='Insérez votre numéro de téléphone'
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phone && touched.phone}
          >
            <Input
              css={`
                ${global.fullWidth}
              `}
            />
          </InputMask>

          <FormHelperText
            id='phone-helper-text'
            error={errors.phone && touched.phone}
            css={`
              ${leftOffset}
            `}
          >
            {touched.phone ? errors.phone : ''}
          </FormHelperText>
        </FormField>

        <FormField
          css={`
            ${global.fullWidth}
          `}
        >
          <FormLabel
            htmlFor='postal-code'
            css={`
              ${leftOffset}
            `}
          >
            Code postal
          </FormLabel>

          <InputMask
            mask='a9a 9a9'
            beforeMaskedStateChange={beforeMaskedStateChange}
            aria-describedby='postal-code-helper-text'
            id='postal-code'
            name='postalCode'
            type='text'
            placeholder='Insérez votre code postal'
            value={values.postalCode}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.postalCode && touched.postalCode}
          >
            <Input
              css={`
                ${global.fullWidth}
              `}
            />
          </InputMask>
          <FormHelperText
            id='postal-code-helper-text'
            error={errors.postalCode && touched.postalCode}
            css={`
              ${leftOffset}
            `}
          >
            {touched.postalCode ? errors.postalCode : ''}
          </FormHelperText>
        </FormField>
      </div>
    </Paper>
  );
};

export default ApplicantStepDetails;
