export const round = (number, precision) => {
  const prec = 10 ** precision;
  const result = Math.round(number * prec) / prec;
  return result;
};

export const compactFormat = amount => {
  if (amount < 1e4) return `${amount}`;

  const { floor, abs, log, min } = Math;
  const abbrev = 'KMB';

  let base = floor(log(abs(amount)) / log(1000));
  const suffix = abbrev[min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;

  return round(amount / 1000 ** base, 2) + suffix;
};

export const format = amount => {
  const formated = new Intl.NumberFormat().format(amount);

  return formated.slice().replace(/,/g, ' ');
};
