// vendors
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import gql from 'graphql-tag';
import { useMutation, useQuery, useApolloClient } from 'react-apollo';
import styled, { css } from 'styled-components';
import 'styled-components/macro';
import { hideVisually } from 'polished';
import {
  global,
  mediaQuery,
  styles,
  utils,
  FormField,
  FormLabel,
  Paper,
  RadioButton,
  Slider,
  sliderStyles,
} from '@pav-apps/design-system';

import { ProductStepSection } from './shared/styles';
import {
  convertPolicyTermToYear,
  convertYearToPolicyTerm,
  marksAmounts,
} from '../../../../utils';
import useDebounce from '../../../../hooks/useDebounce';

const GET_VALUES = gql`
  query getValues {
    policyTerms @client {
      id
    }
  }
`;

const UPDATE_PRODUCTS = gql`
  mutation updateProducts($id: ID!, $quote: LifeInsuranceQuoteInput!) {
    updateLifeInsuranceQuote(id: $id, quote: $quote) {
      success
      message
      session {
        id
        token
        TTL
      }
      quote {
        coverageAmount
        paymentMode
        policyTerms {
          id
          name
          lowerPrice
          selected
        }
        products {
          id
          paymentAmount
          selected
          company {
            name
            logo
          }
          policyTerm {
            id
          }
        }
      }
    }
  }
`;

const ProductStepParamNumbers = styled.span`
  font-size: ${utils.fluid(18)};
  font-weight: 700;
`;

const labelStyle = css`
  font-size: ${utils.fluid(18)};
  font-weight: 700;
  text-transform: uppercase;
`;

const format = amount => {
  const formated = new Intl.NumberFormat().format(amount);

  return formated.slice().replace(/,/g, ' ');
};

const ProductStepParams = ({ onLoadingStart, onLoadingEnd }) => {
  const { setFieldValue, values } = useFormikContext();

  const client = useApolloClient();

  const {
    data: { policyTerms = [] },
  } = useQuery(GET_VALUES);

  const minPolicyTerms = convertPolicyTermToYear(policyTerms[0].id);
  const maxPolicyTerms = convertPolicyTermToYear(
    policyTerms[policyTerms.length - 1].id
  );

  const [updateProducts] = useMutation(UPDATE_PRODUCTS, {
    update(cache) {
      cache.writeData({
        data: {
          coverageAmount: values.coverageAmount,
          products: [],
          isLoading: true,
        },
      });
    },
    onCompleted(result) {
      const { updateLifeInsuranceQuote: data } = result;

      localStorage.setItem('session_token_ttl', data.session.TTL);

      client.writeData({
        data: {
          paymentMode: data.quote.paymentMode || [],
          coverageAmount: data.quote.coverageAmount || [],
          policyTerms: data.quote.policyTerms || [],
          products: data.quote.products || [],
          isLoading: false,
        },
      });
    },
  });

  const handleCoverageAmountUpdate = async val => {
    const id = localStorage.getItem('session_quote_id');

    onLoadingStart();

    await updateProducts({
      variables: { id, quote: { coverageAmount: val } },
    });

    onLoadingEnd();
  };

  const handlePolicyLengthUpdate = async val => {
    const id = localStorage.getItem('session_quote_id');

    onLoadingStart();

    const policyTermSelected = convertYearToPolicyTerm(val);

    await updateProducts({
      variables: { id, quote: { policyTermSelected } },
    });

    onLoadingEnd();
  };

  const handleCoverageAmountChange = async val => {
    setFieldValue('coverageAmount', marksAmounts[val - 1]);
  };

  const handlePolicyLengthChange = async val => {
    setFieldValue('policyLength', val);
  };

  const handlePaymentModeChange = async event => {
    const paymentMode = event.target.value;

    setFieldValue('paymentMode', paymentMode);

    const id = localStorage.getItem('session_quote_id');

    onLoadingStart();

    await updateProducts({
      variables: { id, quote: { paymentMode } },
    });

    onLoadingEnd();
  };

  const currentAmountIndex = marksAmounts.indexOf(values.coverageAmount);

  // Use useRef as a workaround so the linter understands that
  // the referenced values won’t change and avoid infinite loop.
  // See more:
  // - https://github.com/facebook/react/issues/14920
  // - https://blog.logrocket.com/rethinking-hooks-memoization/
  const nonChangesValuesRef = useRef({
    coverageAmountUpdateHandler: handleCoverageAmountUpdate,
    policyLengthUpdateHandler: handlePolicyLengthUpdate,
  });

  const debouncedCoverageAmountVal = useDebounce(values.coverageAmount, 1000);
  // Mimic the componentDidUpdate lifecycle method
  const isInitialMountCoverageAmount = useRef(true);

  useEffect(() => {
    const { coverageAmountUpdateHandler } = nonChangesValuesRef.current;

    if (isInitialMountCoverageAmount.current) {
      isInitialMountCoverageAmount.current = false;
    } else if (debouncedCoverageAmountVal) {
      coverageAmountUpdateHandler(debouncedCoverageAmountVal);
    }
  }, [debouncedCoverageAmountVal]);

  const debouncedPolicyLengthVal = useDebounce(values.policyLength, 1000);
  // Mimic the componentDidUpdate lifecycle method
  const isInitialMountPolicyLength = useRef(true);

  useEffect(() => {
    const { policyLengthUpdateHandler } = nonChangesValuesRef.current;

    if (isInitialMountPolicyLength.current) {
      isInitialMountPolicyLength.current = false;
    } else if (debouncedPolicyLengthVal) {
      policyLengthUpdateHandler(debouncedPolicyLengthVal);
    }
  }, [debouncedPolicyLengthVal]);

  return (
    <ProductStepSection
      css={`
        margin-top: 0;
      `}
    >
      <h3
        css={`
          ${hideVisually}
        `}
      >
        Paramètres
      </h3>

      <p
        css={`
          font-size: ${utils.fluid(18)};
          font-weight: 700;
          margin-bottom: ${utils.fluid(32, 'em')};

          ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
            font-size: ${utils.fluid(22)};
          }
        `}
      >
        Mais vérifions d’abord ces paramètres:
      </p>

      <Paper
        padded
        shadowed
        css={`
          padding: 2em;
          text-align: left;
        `}
      >
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(320px, auto));
            grid-gap: 3.5em;
          `}
        >
          <FormField>
            <div
              css={`
                display: grid;
                grid-template-columns: auto auto;
                justify-content: space-between;
              `}
            >
              <FormLabel
                htmlFor='coverage-amount'
                css={`
                  margin-bottom: 1em;
                  ${labelStyle}
                `}
              >
                Couverture
              </FormLabel>

              <ProductStepParamNumbers>
                {`${format(values.coverageAmount)}$`}
              </ProductStepParamNumbers>
            </div>

            <Slider
              id='coverage-amount'
              name='coverageAmount'
              min={1}
              max={marksAmounts.length}
              value={currentAmountIndex + 1}
              getValueText={() => marksAmounts[currentAmountIndex]}
              onChange={handleCoverageAmountChange}
              css={`
                ${sliderStyles.fullWidth}
                margin-left: -20px;
              `}
            />
          </FormField>

          <FormField>
            <div
              css={`
                display: grid;
                grid-template-columns: auto auto;
                justify-content: space-between;
              `}
            >
              <FormLabel
                htmlFor='policy-length'
                css={`
                  margin-bottom: 1em;
                  ${labelStyle}
                `}
              >
                Durée
              </FormLabel>

              <ProductStepParamNumbers>
                {`${values.policyLength} ans`}
              </ProductStepParamNumbers>
            </div>

            <Slider
              id='policy-length'
              name='policyLength'
              step={5}
              min={minPolicyTerms}
              max={maxPolicyTerms}
              value={values.policyLength}
              onChange={handlePolicyLengthChange}
              css={`
                ${sliderStyles.fullWidth}
                margin-left: -20px;
              `}
            />
          </FormField>

          <fieldset
            css={`
              ${global.resetDefault}
            `}
          >
            <legend
              css={`
                margin-bottom: 1em;
                ${global.fullWidth}
                ${labelStyle}
              `}
            >
              Affichage du prix
            </legend>

            <div
              css={`
                display: grid;
                grid-template-columns: auto auto;
                justify-content: flex-start;
                grid-column-gap: 3em;
                margin: 7px 0;
              `}
            >
              <RadioButton
                name='display-price'
                value='MONTHLY'
                checked={values.paymentMode === 'MONTHLY'}
                onChange={handlePaymentModeChange}
                css={`
                  font-size: ${utils.fluid(21)};
                  text-transform: uppercase;
                `}
              >
                Mensuel
              </RadioButton>

              <RadioButton
                name='display-price'
                value='ANNUAL'
                checked={values.paymentMode === 'ANNUAL'}
                onChange={handlePaymentModeChange}
                css={`
                  font-size: ${utils.fluid(21)};
                  text-transform: uppercase;
                `}
              >
                Annuel
              </RadioButton>
            </div>
          </fieldset>
        </div>
      </Paper>
    </ProductStepSection>
  );
};

ProductStepParams.propTypes = {
  onLoadingStart: PropTypes.func,
  onLoadingEnd: PropTypes.func,
};

ProductStepParams.defaultProps = {
  onLoadingStart: () => {},
  onLoadingEnd: () => {},
};

export default ProductStepParams;
