import styled, { css } from 'styled-components';
import { utils, styles, mediaQuery } from '@pav-apps/design-system';

export const CoverageStepHeading = styled.h2`
  font-size: ${utils.fluid(31)};
  font-weight: 800;
  text-align: center;
  color: ${styles.newColors.grey[500]};
  color: var(--primary-text-color);
  line-height: 1.5;

  max-width: 748px;

  margin: 0 auto;
  padding: ${utils.fluid(24, 'em')} ${utils.fluid(8, 'em')}
    ${utils.fluid(28, 'em')};

  ${mediaQuery.lessThan(styles.breakpoints.xs)} {
    max-width: 270px;
    padding-bottom: ${utils.fluid(16, 'em')};
  }

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(42)};
    line-height: 1.2;
  }

  ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
    font-size: ${utils.fluid(50)};
    line-height: 1.2;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-items: center;
  margin: 4em 0;
`;

export const CoverageStepCardAmount = styled.p`
  font-size: ${utils.fluid(36)};
  font-weight: 700;
  color: ${styles.newColors.grey[500]};

  margin: 0;
  margin-bottom: ${utils.fluid(4, 'em')};

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(50)};
    margin: ${utils.fluid(17, 'em')} 0 ${utils.fluid(8, 'em')};
  }
`;

export const CoverageStepCardHeading = styled.h3`
  font-size: ${utils.fluid(21)};
  font-weight: 800;
  color: ${styles.newColors.grey[500]};
  color: var(--primary-text-color);

  margin: 0.5em 0;

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(25)};
  }
`;

export const CoverageStepCardInfo = styled.p`
  font-size: ${utils.fluid(16)};
  font-weight: 400;
  color: ${styles.newColors.grey[400]}; /* IE11 fallback */
  color: var(--tertiary-text-color);
  align-self: center;

  max-width: 365px;
  margin: 0;

  ${mediaQuery.lessThan(styles.breakpoints.xs)} {
    margin-left: ${utils.fluid(16, 'em')};
    margin-right: ${utils.fluid(16, 'em')};

    color: var(--secondary-text-color);
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 1em 0;
  background-color: ${styles.newColors.grey[400]};
`;

export const mask = css`
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 10px;
  background-color: white;
  z-index: 1000;
`;
