// vendors
import React from 'react';
import { useFormikContext } from 'formik';
import {
  Paper,
  ToggleSelector,
  FormHelperText,
  utils,
  global,
  mediaQuery,
  styles,
} from '@pav-apps/design-system';
import {} from 'styled-components/macro';

const ApplicantStepSmoking = () => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormikContext();

  return (
    <Paper padded bordered shadowed>
      <fieldset
        css={`
          ${global.fullWidth}
          ${global.resetDefault}
        `}
      >
        <legend
          css={`
            width: 95%;
            margin-bottom: ${utils.fluid(32)};
            font-size: ${utils.fluid(17)};
            font-weight: 700;
            color: ${styles.newColors.grey[500]};

            ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
              width: 55%;
            }
          `}
        >
          Consommez-vous du tabac depuis les 5 dernières années?
        </legend>
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-gap: ${utils.fluid(14)};
            justify-content: center;
          `}
        >
          <ToggleSelector
            name='smoking'
            value='true'
            checked={values.smoking === 'true'}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            Oui
          </ToggleSelector>
          <ToggleSelector
            name='smoking'
            value='false'
            checked={values.smoking === 'false'}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            Non
          </ToggleSelector>
        </div>
        {errors.smoking && touched.smoking && (
          <FormHelperText
            id='smoking-helper-text'
            error={errors.smoking && touched.smoking}
          >
            {errors.smoking}
          </FormHelperText>
        )}
      </fieldset>
    </Paper>
  );
};

export default ApplicantStepSmoking;
