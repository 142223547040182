// vendors
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import 'styled-components/macro';
import gql from 'graphql-tag';
import { Container, mediaQuery, styles, utils } from '@pav-apps/design-system';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import './utils/yupPhoneValidation';
import './utils/yupPostalCodeValidation';

import {
  normalizeNumberToString,
  graphQLVariablesReducer,
} from '../../../../utils';

// step parts
import ApplicantStepGender from './ApplicantStepGender';
import ApplicantStepDOB from './ApplicantStepDOB';
import ApplicantStepSmoking from './ApplicantStepSmoking';
import ApplicantStepDetails from './ApplicationStepDetails';
import ApplicantStepBottom from './ApplicantStepBottom';

export const ApplicantStepHeading = styled.h2`
  font-size: 6.5vw;
  font-weight: 800;
  line-height: 1.25;
  text-align: center;
  color: ${styles.newColors.grey[500]}; /* IE11 fallback */
  color: var(--primary-text-color);

  margin-top: 1.5em;
  margin-bottom: 1.5em;

  ${mediaQuery.greaterThan(580)} {
    font-size: ${utils.fluid(38)};
  }
`;

const ApplicantStepContent = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: 2em;
`;

const GET_LOCAL_APPLICANT = gql`
  query {
    quoteId @client
    applicant @client {
      dob
      email
      fullName
      gender
      phone
      postalCode
      healthReport {
        smoking
      }
    }
  }
`;

const CREATE_APPLICANT = gql`
  mutation createApplicant(
    $applicant: ApplicantInput!
    $healthReport: HealthReportInput!
    $session: SessionInput
  ) {
    addLifeInsuranceQuote(
      applicant: $applicant
      healthReport: $healthReport
      session: $session
    ) {
      success
      message
      session {
        id
        token
        TTL
      }
      quote {
        id
        coverageAmount
        policyTerms {
          id
          name
          lowerPrice
          selected
        }
      }
    }
  }
`;

const UPDATE_APPLICANT = gql`
  mutation updateApplicant(
    $id: ID!
    $applicant: ApplicantInput!
    $healthReport: HealthReportInput!
  ) {
    updateLifeInsuranceQuote(
      id: $id
      applicant: $applicant
      healthReport: $healthReport
    ) {
      success
      message
      session {
        id
        token
        TTL
      }
      quote {
        id
        coverageAmount
        policyTerms {
          id
          name
          lowerPrice
          selected
        }
      }
    }
  }
`;

const validateSchema = Yup.object().shape({
  gender: Yup.string().required('Sélectionnez un genre'),
  dobDay: Yup.string().required('Sélectionnez votre jour de naissance'),
  dobMonth: Yup.string().required('Sélectionnez votre mois de naissance'),
  dobYear: Yup.string().required('Sélectionnez votre année de naissance'),
  smoking: Yup.string().required('Sélectionnez une réponse'),
  fullName: Yup.string()
    .required('* Ce champ est requis')
    .min(2, 'Le nom inséré est trop court')
    .max(50, 'Le nom inséré est trop long'),
  email: Yup.string()
    .required('* Ce champ est requis')
    .email('Insérez une adresse courriel valide'),
  phone: Yup.string()
    .required('* Ce champ est requis')
    .phone('CA', 'Insérez un numéro de téléphone valide'),
  postalCode: Yup.string()
    .required('* Ce champ est requis')
    .postalCode('CA', 'Insérez un code postal valide'),
});

const ApplicantStep = ({
  onLoadingStart,
  onLoadingEnd,
  onFinish,
  onComponentDidMount,
  trackingData,
}) => {
  const client = useApolloClient();

  const { data: { applicant, quoteId } = {} } = useQuery(GET_LOCAL_APPLICANT);

  const [createApplicant] = useMutation(CREATE_APPLICANT, {
    update(cache) {
      cache.writeData({
        data: { policyTerms: [], products: [], isLoading: true },
      });
    },
    onCompleted(result) {
      const { addLifeInsuranceQuote: data } = result;

      localStorage.setItem('session_id', data.session.id);
      localStorage.setItem('session_token', data.session.token);
      localStorage.setItem('session_token_ttl', data.session.TTL);
      localStorage.setItem('session_quote_id', data.quote.id);

      client.writeData({
        data: {
          coverageAmount: data.quote.coverageAmount,
          policyTerms: data.quote.policyTerms || [],
          quoteId: data.quote.id,
          isLoading: false,
        },
      });
    },
  });

  const [updateApplicant] = useMutation(UPDATE_APPLICANT, {
    update(cache) {
      cache.writeData({
        data: { policyTerms: [], products: [], isLoading: true },
      });
    },
    onCompleted(result) {
      const { updateLifeInsuranceQuote: data } = result;

      localStorage.setItem('session_token_ttl', data.session.TTL);

      client.writeData({
        data: {
          coverageAmount: data.quote.coverageAmount,
          policyTerms: data.quote.policyTerms || [],
          quoteId: data.quote.id,
          isLoading: false,
        },
      });
    },
  });

  const initialValues = {
    fullName: '',
    dobDay: '',
    dobMonth: '',
    dobYear: '',
    smoking: '',
    email: '',
    phone: '',
    postalCode: '',
    gender: '',
  };

  if (applicant) {
    const dob = applicant.dob ? new Date(applicant.dob) : undefined;

    initialValues.fullName = applicant.fullName || '';
    initialValues.dobDay = dob
      ? normalizeNumberToString(dob.getUTCDate(), 2)
      : '';
    initialValues.dobMonth = dob
      ? normalizeNumberToString(dob.getUTCMonth() + 1, 2)
      : '';
    initialValues.dobYear = dob
      ? normalizeNumberToString(dob.getUTCFullYear(), 4)
      : '';
    initialValues.smoking =
      typeof applicant.healthReport.smoking === 'boolean'
        ? String(applicant.healthReport.smoking)
        : '';
    initialValues.email = applicant.email || '';
    initialValues.phone = applicant.phone || '';
    initialValues.postalCode = applicant.postalCode || '';
    initialValues.gender = applicant.gender || '';
  }

  useEffect(() => {
    onComponentDidMount();
  }, [onComponentDidMount]);

  const handleSubmit = async (values, actions) => {
    const variables = graphQLVariablesReducer(values);
    const sessionTokenTTL = localStorage.getItem('session_token_ttl');

    const isUpdating =
      sessionTokenTTL && Number(sessionTokenTTL) > Number(Date.now());

    onLoadingStart();

    const result = isUpdating
      ? await updateApplicant({ variables: { id: quoteId, ...variables } })
      : await createApplicant({
          variables: {
            ...variables,
            session: {
              ...trackingData,
            },
          },
        });

    const {
      data: {
        [isUpdating ? 'updateLifeInsuranceQuote' : 'addLifeInsuranceQuote']: {
          success,
          message,
          quote,
        },
      },
    } = result;

    onLoadingEnd();

    client.writeData({
      data: {
        applicant: {
          __typename: 'Applicant',
          ...variables.applicant,
          healthReport: {
            __typename: 'HealthReport',
            ...variables.healthReport,
          },
        },
      },
    });

    actions.setSubmitting(false);

    if (!success) {
      console.error(message);

      return false;
    }

    onFinish({
      data: { id: quote.id || undefined, ...variables },
      isUpdating: isUpdating || false,
    });

    return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validateSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: formikHandleSubmit }) => (
        <>
          <Container maxWidth='sm'>
            <form onSubmit={formikHandleSubmit}>
              <ApplicantStepHeading>
                Assurance vie
                <br />
                Obtenez les meilleurs prix,
                <br />
                en 30 secondes!
              </ApplicantStepHeading>

              <ApplicantStepContent>
                <ApplicantStepGender />
                <ApplicantStepDOB />
                <ApplicantStepSmoking />
                <ApplicantStepDetails />
              </ApplicantStepContent>
              <ApplicantStepBottom />
            </form>
          </Container>
        </>
      )}
    </Formik>
  );
};

ApplicantStep.propTypes = {
  onLoadingStart: PropTypes.func,
  onLoadingEnd: PropTypes.func,
  onFinish: PropTypes.func,
  onComponentDidMount: PropTypes.func,
  trackingData: PropTypes.shape({
    utm: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    device: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    referrer: PropTypes.string.isRequired,
  }),
};

ApplicantStep.defaultProps = {
  onLoadingStart: () => {},
  onLoadingEnd: () => {},
  onFinish: () => {},
  onComponentDidMount: () => {},
  trackingData: {
    utm: [],
    device: '',
    origin: '',
    referrer: '',
  },
};

export default ApplicantStep;
