import styled, { css } from 'styled-components';

import { styles, utils, mediaQuery, Button } from '@pav-apps/design-system';

export const ProductStepSection = styled.section`
  margin: ${utils.fluid(12, 'em')} 0;
  text-align: center;

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    margin: ${utils.fluid(32, 'em')} 0;
  }
`;

export const ProductStepSectionHeading = styled.h3`
  font-size: ${utils.fluid(21)};
  font-weight: 700;
  line-height: ${(18 / 16) * 1};
  margin: ${utils.fluid(4, 'em')} 0;

  color: ${styles.newColors.grey[800]}; /* IE11 fallback */
  color: var(--secondary-text-color);

  ${mediaQuery.lessThan(styles.breakpoints.xs)} {
    line-height: ${(22 / 16) * 1};
  }

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(36)};
  }

  ${mediaQuery.greaterThan(styles.breakpoints.md)} {
    font-size: ${utils.fluid(53)};
  }
`;

export const ProductStepSectionDescription = styled.p`
  font-size: ${utils.fluid(13)};
  font-weight: 400;
  line-height: ${(28 / 16) * 1};

  color: ${styles.newColors.grey[800]}; /* IE11 fallback */
  color: var(--secondary-text-color);

  margin-top: ${utils.fluid(16, 'em')};
  margin-bottom: ${utils.fluid(32, 'em')};

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(14)};
  }
`;

export const ProductStepParamNumbers = styled.span`
  font-size: ${utils.fluid(18)};
  font-weight: 700;
`;

export const labelStyle = css`
  font-size: ${utils.fluid(18)};
  font-weight: 700;
  text-transform: uppercase;
`;

export const ChoosePlanButton = styled(Button)`
  && {
    letter-spacing: 1px;
    margin-top: ${utils.fluid(56, 'em')};
    display: none;

    ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
      display: inline-flex;
    }
  }
`;
