// vendors
import React from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import {
  Paper,
  ToggleSelector,
  FormHelperText,
  icons,
  utils,
  global,
  styles,
  toggleSelectorStyles,
} from '@pav-apps/design-system';
import {} from 'styled-components/macro';

const StyledGenderIcon = css`
  height: 21px;
  width: 17px;
  margin-right: 6px;
  cursor: pointer;
  pointer-events: none;
`;

const StyledGenderMaleIcon = styled(icons.GenderMale)`
  ${StyledGenderIcon};
`;

const StyledGenderFemaleIcon = styled(icons.GenderFemale)`
  ${StyledGenderIcon};
`;

const ApplicantStepGender = () => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormikContext();

  return (
    <Paper padded bordered shadowed>
      <fieldset
        css={`
          ${global.fullWidth}
          ${global.resetDefault}
        `}
      >
        <legend
          css={`
            margin-bottom: ${utils.fluid(32)};
            font-size: ${utils.fluid(21)};
            font-weight: 600;
            color: ${styles.newColors.grey[500]};
          `}
        >
          Genre
        </legend>
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: ${utils.fluid(14)};
            ${global.fullWidth}
          `}
        >
          <ToggleSelector
            aria-describedby='gender-helper-text'
            name='gender'
            value='MALE'
            checked={values.gender === 'MALE'}
            onChange={handleChange}
            onBlur={handleBlur}
            css={`
              ${toggleSelectorStyles.fullWidth}
            `}
          >
            <StyledGenderMaleIcon />
            <span>Homme</span>
          </ToggleSelector>
          <ToggleSelector
            aria-describedby='gender-helper-text'
            name='gender'
            value='FEMALE'
            checked={values.gender === 'FEMALE'}
            onChange={handleChange}
            onBlur={handleBlur}
            css={`
              ${toggleSelectorStyles.fullWidth}
            `}
          >
            <StyledGenderFemaleIcon />
            <span>Femme</span>
          </ToggleSelector>
          {errors.gender && touched.gender && (
            <FormHelperText
              id='gender-helper-text'
              error={errors.gender && touched.gender}
              css={`
                grid-column: span 2;
              `}
            >
              {errors.gender}
            </FormHelperText>
          )}
        </div>
      </fieldset>
    </Paper>
  );
};

export default ApplicantStepGender;
