// vendors
import React, { useState } from 'react';
import { Formik } from 'formik';
import { useMutation, useQuery, useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';
import { styles, utils, mediaQuery, Container } from '@pav-apps/design-system';

import {
  ProductStepSection,
  ProductStepSectionHeading,
  ProductStepSectionDescription,
} from './shared/styles';

import {
  MobileOnly,
  DesktopOnly,
  StickyContainer,
  StickyButton,
} from '../../../../shared/styles';

import {
  ProductStepHeading,
  ProductStepDescription,
  ProductList,
} from './ProductStep.styles';

import ProductStepParams from './ProductStepParams';

import ProductCard from './ProductCard';

import {
  graphQLVariablesReducer,
  convertPolicyTermToYear,
} from '../../../../utils';

const GET_VALUES = gql`
  query getValues {
    quoteId @client
    coverageAmount @client
    paymentMode @client
    policyTerms @client {
      id
      name
      lowerPrice
      selected
    }
    products @client {
      id
      paymentAmount
      selected
      policyTerm {
        id
      }
      company {
        name
        logo
      }
    }
  }
`;

const SELECT_PRODUCT_IN_QUOTE = gql`
  mutation closeQuote($id: ID!, $quote: LifeInsuranceQuoteInput!) {
    closeLifeInsuranceQuote(id: $id, quote: $quote) {
      success
      message
      session {
        id
        token
        TTL
      }
      quote {
        coverageAmount
        paymentMode
        policyTerms {
          id
          name
          lowerPrice
          selected
        }
        products {
          id
          paymentAmount
          selected
          company {
            name
            logo
          }
          policyTerm {
            id
          }
        }
      }
    }
  }
`;

const productGroupIsTermFromPolicyTermObject = ({ policyTerm: { id } }) =>
  Number(id.split(':')[0]) === Number(0);
const productGroupIsTerm100FromPolicyTermObject = ({ policyTerm: { id } }) =>
  Number(id.split(':')[0]) === Number(2);

const ProductStep = ({ onLoadingStart, onLoadingEnd, onFinish }) => {
  const [displayStickyButton, setDisplayStickyButton] = useState(false);

  const client = useApolloClient();

  const [closeQuote] = useMutation(SELECT_PRODUCT_IN_QUOTE, {
    update(cache) {
      cache.writeData({
        data: { isLoading: true },
      });
    },
    onCompleted(result) {
      const { closeLifeInsuranceQuote: data } = result;

      localStorage.setItem('session_token_ttl', data.session.TTL);

      client.writeData({
        data: {
          paymentMode: data.quote.paymentMode || [],
          coverageAmount: data.quote.coverageAmount || [],
          policyTerms: data.quote.policyTerms || [],
          products: data.quote.products || [],
          isLoading: false,
        },
      });
    },
  });

  const {
    data: {
      products = [],
      policyTerms = [],
      coverageAmount,
      paymentMode,
      quoteId,
    },
  } = useQuery(GET_VALUES);

  const policyLengthId =
    (policyTerms.find(({ selected }) => selected) || {}).id || '';

  const policyLength = convertPolicyTermToYear(policyLengthId);

  const productSelectedId =
    (products.find(({ selected }) => selected) || {}).id || '';

  const initialValues = {
    featureProductSelected: null,
    productSelected: {
      id: productSelectedId,
      type: '',
      term: '',
    },
    policyLength,
    coverageAmount,
    paymentMode,
  };

  const handleSubmit = async (values, actions) => {
    const params = { ...values };

    const variables = graphQLVariablesReducer(params);

    variables.id = quoteId;

    onLoadingStart();

    const result = await closeQuote({
      variables: { id: quoteId, ...variables },
    });

    onLoadingEnd();

    const {
      data: {
        closeLifeInsuranceQuote: { success, message },
      },
    } = result;

    actions.setSubmitting(false);

    if (!success) {
      console.error(message);

      return false;
    }

    onFinish();

    return true;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        handleBlur,
        setFieldValue,
        values,
        handleSubmit: formikHandleSubmit,
      }) => (
        <>
          <form onSubmit={formikHandleSubmit}>
            <Container
              padded
              maxWidth='lg'
              css={`
                display: flex;
                flex-direction: column;
                padding-bottom: ${67 * 1.1 * 1}px;
              `}
            >
              <DesktopOnly>
                <ProductStepHeading>Voici vos soumissions!</ProductStepHeading>

                <ProductStepParams
                  onLoadingStart={onLoadingStart}
                  onLoadingEnd={onLoadingEnd}
                />
              </DesktopOnly>

              <MobileOnly>
                <ProductStepHeading>
                  Il est temps de comparer!
                </ProductStepHeading>

                <ProductStepDescription>
                  Voici une sélection de compagnies d’assurances qui
                  correspondent à vos besoins et toujours au meilleur prix du
                  marché.
                </ProductStepDescription>
              </MobileOnly>

              <ProductStepSection
                css={`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <ProductStepSectionHeading>
                  Le top 3 de Picki
                </ProductStepSectionHeading>

                <p
                  css={`
                    font-size: ${utils.fluid(13)};
                    font-weight: 400;

                    color: ${styles.newColors.grey[800]}; /* IE11 fallback */
                    color: var(--secondary-text-color);

                    max-width: 900px;
                    margin-top: ${utils.fluid(16, 'em')};
                    margin-bottom: ${utils.fluid(28, 'em')};

                    ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
                      font-size: ${utils.fluid(16)};
                      font-weight: 400;

                      margin-top: ${utils.fluid(24, 'em')};
                      margin-bottom: ${utils.fluid(80, 'em')};
                    }
                  `}
                >
                  Picki vous propose les trois polices d’assurance les moins
                  chères. Discutez avec votre conseiller Picki pour vérifier
                  avec lui si ces offres répondent à vos besoins.
                </p>
                <div
                  css={`
                    display: grid;
                    grid-gap: 1.5em;
                    align-self: normal;

                    ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
                      grid-template-columns: repeat(3, 1fr);
                    }
                  `}
                >
                  {products
                    .filter(productGroupIsTermFromPolicyTermObject)
                    .slice(0, 3)
                    .map(product => (
                      <ProductCard
                        key={product.id}
                        name='featureProductSelected'
                        value={product.id}
                        amount={String(product.paymentAmount)}
                        term={product.policyTerm.id}
                        period={
                          values.paymentMode === 'MONTHLY' ? 'mois' : 'an'
                        }
                        checked={values.productSelected.id === product.id}
                        onChange={e => {
                          setFieldValue(
                            'featureProductSelected',
                            e.target.value
                          );
                          setFieldValue('productSelected[id]', e.target.value);

                          const policyTerm = product.policyTerm.id.split(':');
                          setFieldValue('productSelected[type]', policyTerm[0]);
                          setFieldValue('productSelected[term]', policyTerm[1]);
                        }}
                        onBlur={handleBlur}
                        logo={{
                          src: product.company.logo,
                          alt: product.company.name,
                        }}
                      />
                    ))}
                </div>
              </ProductStepSection>

              <Container
                maxWidth='md'
                css={`
                  display: flex;
                  flex-direction: column;

                  && {
                    > * {
                      margin-top: ${utils.fluid(36, 'em')};

                      ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
                        margin-top: ${utils.fluid(48, 'em')};
                      }

                      ${mediaQuery.greaterThan(styles.breakpoints.md)} {
                        margin-top: ${utils.fluid(85, 'em')};
                      }
                    }
                  }
                `}
              >
                <ProductStepSection
                  css={`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <ProductStepSectionHeading>
                    Police d’assurance temporaire
                  </ProductStepSectionHeading>

                  <ProductStepSectionDescription>
                    Une assurance pour couvrir vos besoins financiers sur
                    quelques années.
                  </ProductStepSectionDescription>

                  <ProductList>
                    {products
                      .filter(productGroupIsTermFromPolicyTermObject)
                      .map((product, index) => (
                        <ProductCard
                          detailed
                          key={product.id}
                          name='productSelected'
                          value={product.id}
                          amount={String(product.paymentAmount)}
                          term={product.policyTerm.id}
                          period={
                            values.paymentMode === 'MONTHLY' ? 'mois' : 'an'
                          }
                          primary={index < 3}
                          checked={values.productSelected.id === product.id}
                          onChange={e => {
                            setFieldValue(
                              'productSelected[id]',
                              e.target.value
                            );

                            const policyTerm = product.policyTerm.id.split(':');
                            setFieldValue(
                              'productSelected[type]',
                              policyTerm[0]
                            );
                            setFieldValue(
                              'productSelected[term]',
                              policyTerm[1]
                            );
                          }}
                          onBlur={handleBlur}
                          logo={{
                            src: product.company.logo,
                            alt: product.company.name,
                          }}
                        />
                      ))}
                  </ProductList>
                </ProductStepSection>

                <ProductStepSection
                  css={`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <ProductStepSectionHeading>
                    Police d’assurance permanente
                  </ProductStepSectionHeading>

                  <ProductStepSectionDescription>
                    Une assurance pour vous protéger la vie entière, et ce, sans
                    augmentation de primes.
                  </ProductStepSectionDescription>

                  {/* <ProductCard
                    detailed
                    name='productSelected'
                    value='5'
                    amount='40,99'
                    term={values.paymentMode === 'MONTHLY' ? 'mois' : 'an'}
                    checked={values.productSelected === '5'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    logo={{
                      src:
                        'https://dummyimage.com/180x90/cccccc/fff.png&text=PIC',
                      srcset: '',
                      srcsetwebp: '',
                      alt: 'logo',
                    }}
                  /> */}

                  <ProductList>
                    {products
                      .filter(productGroupIsTerm100FromPolicyTermObject)
                      .map((product, index) => (
                        <ProductCard
                          key={product.id}
                          detailed
                          name='productSelected'
                          value={product.id}
                          amount={String(product.paymentAmount)}
                          term={product.policyTerm.id}
                          period={
                            values.paymentMode === 'MONTHLY' ? 'mois' : 'an'
                          }
                          primary={index < 3}
                          checked={values.productSelected.id === product.id}
                          onChange={e => {
                            setFieldValue(
                              'productSelected[id]',
                              e.target.value
                            );

                            const policyTerm = product.policyTerm.id.split(':');
                            setFieldValue(
                              'productSelected[type]',
                              policyTerm[0]
                            );
                            setFieldValue(
                              'productSelected[term]',
                              policyTerm[1]
                            );
                          }}
                          onBlur={handleBlur}
                          logo={{
                            src: product.company.logo,
                            alt: product.company.name,
                          }}
                        />
                      ))}
                  </ProductList>
                </ProductStepSection>
              </Container>
            </Container>

            {setDisplayStickyButton(values.productSelected.id !== '')}

            <CSSTransition
              classNames='sticky'
              in={displayStickyButton}
              timeout={0}
            >
              <StickyContainer>
                <StickyButton type='submit'>Choisir cette offre</StickyButton>
              </StickyContainer>
            </CSSTransition>
          </form>
        </>
      )}
    </Formik>
  );
};

ProductStep.propTypes = {
  onLoadingStart: PropTypes.func,
  onLoadingEnd: PropTypes.func,
  onFinish: PropTypes.func,
};

ProductStep.defaultProps = {
  onLoadingStart: () => {},
  onLoadingEnd: () => {},
  onFinish: () => {},
};

export default ProductStep;
