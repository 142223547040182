// vendors
import React, { useEffect } from 'react';
import 'styled-components/macro';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import {
  Carousel,
  carouselStyles,
  Container,
  Paper,
  mediaQuery,
  styles,
  utils,
} from '@pav-apps/design-system';

import {
  SummaryHeading,
  SummaryHeadingIcon,
  SummaryHeadingDescription,
  SummaryBlock,
  SummaryBlockHeading,
  // SummaryReferenceNumberTitle,
  // SummaryReferenceNumber,
  SummaryPrice,
  SummaryPriceSup,
  SummaryTerm,
  SummaryPriceCaption,
  SummaryProfileCaption,
  SummaryValue,
  SummaryDescription,
  Separator,
  Comment,
  Author,
} from './Summary.styles';
import { normalizeNumberToString } from '../../../../utils';

const GET_QUOTE = gql`
  query {
    coverageAmount @client
    quoteId @client
    paymentMode @client
    products @client {
      id
      paymentAmount
      selected
      company {
        name
        logo
      }
    }
    applicant @client {
      email
      fullName
      gender
      phone
      postalCode
      healthReport {
        smoking
      }
    }
    policyTerms @client {
      id
      name
      selected
    }
  }
`;

const comments = [
  {
    body:
      'Excellent service. Le courriel envoyé a bien répondu à mes attentes et m’a donné l’information pertinente.',
    cite: 'Jean-Pierre Weilbrenner',
  },
  {
    body:
      'Exellent service. Prix, personalisation et courtoisie au rdv. Merci!',
    cite: 'Bertrand Hodgson',
  },
  {
    body:
      'Très bon service! Qui a répondu à toute me attente. Merci à M. Steven Bourgoin',
    cite: 'Steve Michaud',
  },
  {
    body:
      'Un service propre aux besoins des clients bien expliqué sous toutes les possibilités.',
    cite: 'Marie-Ève',
  },
  {
    body:
      'Parle très clairement et comprend très bien se qu’il dit et j’ai reçu un excellent service',
    cite: 'Anouk',
  },
];

const Summary = () => {
  const {
    data: { policyTerms, products, applicant, coverageAmount, paymentMode },
  } = useQuery(GET_QUOTE);

  const term = policyTerms.find(({ selected }) => selected) || {
    name: "Police d'assurance permanent",
  };

  const product = products.find(({ selected }) => selected);

  const priceInteger = Math.floor(product.paymentAmount);
  const priceDecimal = normalizeNumberToString(
    Math.round((product.paymentAmount - priceInteger) * 100),
    2
  );

  const resetSessionTokenTTL = () => {
    localStorage.setItem('session_token_ttl', 0);
  };

  // Reset session token TTL when refreshing the page.
  useEffect(() => {
    window.addEventListener('beforeunload', resetSessionTokenTTL);
    // On Safari iOS
    window.addEventListener('pagehide', resetSessionTokenTTL);

    return () => {
      window.removeEventListener('beforeunload', resetSessionTokenTTL);
      window.removeEventListener('pagehide', resetSessionTokenTTL);
    };
  }, []);

  // FIXME: The history function call document. This block the build of static website.
  // import history from 'history/browser';
  // useEffect(() => {
  //   // Listen for changes to the browser action history.
  //   const unListen = history.listen(({ action }) => {
  //     // Reset session token TTL when user click on browser navigation buttons
  //     if (action === 'POP') {
  //       resetSessionTokenTTL();
  //     }
  //   });

  //   return () => {
  //     unListen();
  //   };
  // }, []);

  return (
    <>
      <Container
        maxWidth='md'
        padded
        css={`
          padding-bottom: ${67 * 1.1 * 1}px;
        `}
      >
        <div
          css={`
            ${utils.flexCentered}
            flex-direction: column;
            padding-top: ${utils.fluid(48, 'em')};
            text-align: center;
          `}
        >
          <SummaryHeadingIcon />

          <SummaryHeading>Nous y sommes! Voici la suite:</SummaryHeading>

          <SummaryHeadingDescription>
            Attendez-vous à recevoir un appel de l’un de nos conseillers
            certifiés dans le but réviser vos informations et la protection que
            vous avez choisi.
          </SummaryHeadingDescription>
        </div>

        <div
          css={`
            display: grid;
            grid-gap: 1.5em;
            align-items: flex-start;
            justify-content: center;
            grid-template-columns: 1fr;

            ${mediaQuery.greaterThan(1080)} {
              grid-template-columns: minmax(526px, 1fr) minmax(370px, auto);
            }
          `}
        >
          <SummaryBlock>
            <SummaryBlockHeading
              css={`
                ${mediaQuery.lessThan(1080)} {
                  font-size: ${utils.fluid(21)};
                }
              `}
            >
              Votre Prix
            </SummaryBlockHeading>

            <Paper shadowed>
              <div
                css={`
                  display: grid;
                  grid-template-areas: 'top' 'price' 'profile';
                  grid-template-columns: 1fr;
                  grid-row-gap: ${utils.fluid(20, 'em')};
                  padding: ${utils.fluid(24, 'em')};

                  ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
                    grid-template-areas: 'top top' 'price profile';
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: ${utils.fluid(56, 'em')};
                    grid-row-gap: ${utils.fluid(32, 'em')};
                    padding-left: ${utils.fluid(48, 'em')};
                  }
                `}
              >
                <div
                  css={`
                    grid-area: top;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: ${utils.fluid(8, 'em')};
                  `}
                >
                  <picture>
                    <img
                      src={product.company.logo}
                      alt={product.company.name}
                    />
                  </picture>

                  {/* <div
                    css={`
                      text-align: right;
                    `}
                  >
                    <SummaryReferenceNumberTitle>
                      Numéro de référence
                    </SummaryReferenceNumberTitle>

                    <SummaryReferenceNumber>iD-689580</SummaryReferenceNumber>
                  </div> */}
                </div>

                <div
                  css={`
                    grid-area: price;
                  `}
                >
                  <div
                    css={`
                      position: relative;
                      line-height: 1.2;
                      margin-bottom: ${utils.fluid(14, 'em')};

                      display: grid;
                      justify-content: flex-start;
                      grid-template-areas:
                        'price sup'
                        'price term';
                    `}
                  >
                    <SummaryPrice>{priceInteger}</SummaryPrice>
                    <SummaryPriceSup>{`,${priceDecimal}$`}</SummaryPriceSup>
                    <SummaryTerm>
                      {`/ ${paymentMode === 'MONTHLY' ? 'mois' : 'an'}`}
                    </SummaryTerm>
                  </div>

                  <div>
                    <SummaryPriceCaption>
                      Montant de la couverture
                    </SummaryPriceCaption>
                    <SummaryValue>
                      {coverageAmount.toLocaleString('fr-CA', {
                        style: 'currency',
                        currency: 'CAD',
                        minimumFractionDigits: 0,
                      })}
                    </SummaryValue>

                    <Separator />

                    <SummaryPriceCaption>Durée du terme</SummaryPriceCaption>
                    <SummaryValue>{term.name}</SummaryValue>

                    <SummaryDescription>
                      Assurez-vous que cette offre répond à vos besoins en
                      parlant à notre conseiller Picki
                    </SummaryDescription>
                  </div>
                </div>

                <div
                  css={`
                    grid-area: profile;
                    margin-top: ${utils.fluid(8, 'em')};
                  `}
                >
                  <div>
                    <SummaryProfileCaption>Nom complet</SummaryProfileCaption>
                    <SummaryValue>{applicant.fullName}</SummaryValue>

                    <Separator />

                    <SummaryProfileCaption>Téléphone</SummaryProfileCaption>
                    <SummaryValue>{applicant.phone}</SummaryValue>

                    <Separator />

                    <SummaryProfileCaption>Courriel</SummaryProfileCaption>
                    <SummaryValue>{applicant.email}</SummaryValue>

                    <Separator />

                    <SummaryProfileCaption>Code postal</SummaryProfileCaption>
                    <SummaryValue>{applicant.postalCode}</SummaryValue>

                    <Separator />

                    <SummaryProfileCaption>Fumeur</SummaryProfileCaption>
                    <SummaryValue>
                      {applicant.healthReport.smoking ? 'Oui' : 'Non'}
                    </SummaryValue>
                  </div>
                </div>
              </div>
            </Paper>
          </SummaryBlock>

          <SummaryBlock>
            <SummaryBlockHeading
              css={`
                ${mediaQuery.lessThan(1080)} {
                  font-size: ${utils.fluid(17)};
                }
              `}
            >
              Ce qu’ils pensent de nous
            </SummaryBlockHeading>

            <Paper padded shadowed>
              <Carousel
                slidesToShow={1}
                looped
                css={`
                  ${carouselStyles.navigationDown}
                  ${carouselStyles.noPadding}
                    grid-row-gap: 0;
                `}
              >
                {comments.map(comment => (
                  <div
                    css={`
                      ${utils.flexCentered}
                      flex-direction: column;
                      text-align: center;

                      height: 100%;
                      padding: 0 1em;
                    `}
                  >
                    <Comment>
                      <span>« </span>
                      {comment.body}
                      <span> »</span>
                    </Comment>

                    <Author>{comment.cite}</Author>
                  </div>
                ))}
              </Carousel>
            </Paper>
          </SummaryBlock>
        </div>
      </Container>
    </>
  );
};

export default Summary;
