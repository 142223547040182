// vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { useMutation, useQuery, useApolloClient } from 'react-apollo';
import { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import {
  styles,
  utils,
  Button,
  Carousel,
  FormHelperText,
  Loader,
  Paper,
} from '@pav-apps/design-system';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import {} from 'styled-components/macro';

import { graphQLVariablesReducer } from '../../../../utils';

import { StickyContainer, StickyButton } from '../../../../shared/styles';

import {
  CoverageStepHeading,
  Container,
  CoverageStepCardHeading,
  CoverageStepCardInfo,
  mask,
} from './CoverageStep.styles';

import AmountSection from './AmountSection';

import PolicyCard from './PolicyCard';

const GET_VALUES = gql`
  query getPolicyTerms {
    quoteId @client
    coverageAmount @client
    policyTerms @client {
      id
      name
      lowerPrice
      selected
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($id: ID!, $quote: LifeInsuranceQuoteInput!) {
    updateLifeInsuranceQuote(id: $id, quote: $quote) {
      success
      message
      session {
        id
        token
        TTL
      }
      quote {
        paymentMode
        products {
          id
          paymentAmount
          selected
          company {
            name
            logo
          }
          policyTerm {
            id
          }
        }
        policyTerms {
          id
          name
          lowerPrice
          selected
        }
      }
    }
  }
`;

const validateSchema = Yup.object().shape({
  policyLength: Yup.string().required('Sélectionnez une durée'),
});

const CoverageStep = ({ onLoadingStart, onLoadingEnd, onFinish }) => {
  const [contentLoading, setContentLoading] = useState(false);
  const [displayStickyButton, setDisplayStickyButton] = useState(false);

  const client = useApolloClient();
  const {
    data: { policyTerms = [], coverageAmount, quoteId },
  } = useQuery(GET_VALUES);

  const policyLength =
    (policyTerms.find(({ selected }) => selected) || {}).id || '';

  const [updateQuote] = useMutation(UPDATE_QUOTE, {
    update(cache) {
      cache.writeData({ data: { products: [], isLoading: true } });
    },
    onCompleted(result) {
      const { updateLifeInsuranceQuote: data } = result;

      localStorage.setItem('session_token_ttl', data.session.TTL);

      client.writeData({
        data: {
          paymentMode: data.quote.paymentMode || 'MONTHLY',
          products: data.quote.products || [],
          isLoading: false,
        },
      });
    },
  });

  const handleSubmit = async (values, actions) => {
    const variables = graphQLVariablesReducer(values);

    variables.id = quoteId;

    onLoadingStart();

    const result = await updateQuote({ variables });

    onLoadingEnd();

    const {
      data: {
        updateLifeInsuranceQuote: { success, message },
      },
    } = result;

    client.writeData({
      data: {
        coverageAmount: variables.quote.coverageAmount,
      },
    });

    actions.setSubmitting(false);

    if (!success) {
      console.error(message);

      return false;
    }

    onFinish();

    return true;
  };

  const handleContentLoadingStart = () => {
    setContentLoading(true);
  };

  const handleContentLoadingEnd = () => {
    setContentLoading(false);
  };

  const isMobile = useMediaQuery(
    `(max-width: ${styles.breakpoints.xs - 0.02}px)`
  );

  return (
    <Formik
      initialValues={{ coverageAmount, policyLength }}
      validationSchema={validateSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit: formikHandleSubmit,
        values,
        touched,
        errors,
      }) => (
        <>
          <form
            onSubmit={formikHandleSubmit}
            css={`
              padding-bottom: ${67 * 1.1 * 1}px;
            `}
          >
            <CoverageStepHeading>
              {isMobile
                ? `Sélectionner une couverture`
                : `Sélectionner une couverture pour démarrer`}
            </CoverageStepHeading>

            <div
              css={`
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(280px, 525px));
                grid-gap: 1.2em;
                justify-content: center;
              `}
            >
              <AmountSection
                onContentLoadingStart={handleContentLoadingStart}
                onContentLoadingEnd={handleContentLoadingEnd}
              />

              <Paper
                padded={!isMobile}
                shadowed={!isMobile}
                css={`
                  ${global.boxSizingReset}
                  min-height: 400px;

                  ${isMobile &&
                    css`
                      min-height: 0;
                      padding: ${utils.fluid(20, 'em')};
                      background: none;
                    `}
                `}
              >
                <div
                  css={`
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                  `}
                >
                  <CoverageStepCardHeading
                    css={`
                      align-self: center;
                      max-width: 275px;
                      line-height: 1.2;
                    `}
                  >
                    Durée du terme de la police d’assurance
                  </CoverageStepCardHeading>

                  <CoverageStepCardInfo>
                    Les planificateurs financiers recommandent de choisir un
                    terme suffisamment long pour couvrir les besoins financiers
                    futurs de votre famille.
                  </CoverageStepCardInfo>

                  {isMobile ? (
                    <>
                      <p
                        css={`
                          font-size: ${utils.fluid(16)};
                          font-weight: 600;
                          color: var(--primary-text-color);
                          margin-top: 2em;
                        `}
                      >
                        Mensualité à partir de :
                      </p>

                      <div
                        css={`
                          display: grid;
                          grid-gap: ${utils.fluid(24, 'em')};
                        `}
                      >
                        {policyTerms.map(term => (
                          <PolicyCard
                            label={term.name}
                            value={term.id}
                            amount={term.lowerPrice}
                            checked={values.policyLength === term.id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    <div
                      css={`
                        position: relative;
                        margin: 1em 0;
                      `}
                    >
                      {contentLoading && (
                        <div
                          css={`
                            ${utils.flexCentered}
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 10000;
                          `}
                        >
                          <div
                            css={`
                              ${utils.flexCentered}
                              width: 100%;
                              height: 100%;
                              background-color: ${styles.newColors.white
                                .default};
                              margin: 0
                                ${policyTerms.length === 1 ? `0` : `3em`};
                            `}
                          >
                            <Loader />
                          </div>
                        </div>
                      )}

                      <Carousel
                        slidesToShow={2}
                        looped
                        css={`
                          position: relative;

                          li:last-child > * {
                            border: 0;
                          }

                          ${policyTerms.length > 1 &&
                            css`
                              > :first-child:before {
                                ${mask}
                                left: 42px;
                              }
                              > :first-child:after {
                                ${mask}
                                right: 42px;
                              }
                            `}
                        `}
                      >
                        {policyTerms.map(term => (
                          <PolicyCard
                            aria-describedby='policyLength-helper-text'
                            label={term.name}
                            value={term.id}
                            amount={term.lowerPrice}
                            checked={values.policyLength === term.id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        ))}
                      </Carousel>
                      {errors.policyLength && touched.policyLength && (
                        <FormHelperText
                          id='policyLength-helper-text'
                          error={errors.policyLength && touched.policyLength}
                          css={`
                            text-align: left;
                          `}
                        >
                          {errors.policyLength}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                </div>
              </Paper>
            </div>

            {setDisplayStickyButton(values.policyLength !== '')}

            {isMobile ? (
              <CSSTransition
                classNames='sticky'
                in={displayStickyButton}
                timeout={0}
              >
                <StickyContainer>
                  <StickyButton type='submit'>
                    Comparer les assureurs
                  </StickyButton>
                </StickyContainer>
              </CSSTransition>
            ) : (
              <Container>
                <Button type='submit'>Comparer les assureurs</Button>
              </Container>
            )}
          </form>
        </>
      )}
    </Formik>
  );
};

CoverageStep.propTypes = {
  onLoadingStart: PropTypes.func,
  onLoadingEnd: PropTypes.func,
  onFinish: PropTypes.func,
};

CoverageStep.defaultProps = {
  onLoadingStart: () => {},
  onLoadingEnd: () => {},
  onFinish: () => {},
};

export default CoverageStep;
