// vendors
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { HashRouter as Router } from 'react-router-dom';

import fetch from 'unfetch';

import { global } from '@pav-apps/design-system';
import LifeInsuranceForm from '../LifeInsuranceForm';

import { resolvers, typeDefs } from '../../resolvers';

const uri = process.env.REACT_APP_API_URI || 'https://api.picki.ca/graphql/';

function App({ apiKey, onSubmitted, onSubmittedStep, trackingData }) {
  const [client, setClient] = useState(null);

  useEffect(() => {
    const cache = new InMemoryCache();

    const httpLink = new HttpLink({ uri, fetch });

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const sessionToken = localStorage.getItem('session_token');
      const sessionId = localStorage.getItem('session_id');

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          picki_session_token: sessionToken,
          picki_session_id: sessionId,
          picki_api_key: apiKey,
        },
      };
    });

    setClient(
      new ApolloClient({
        cache,
        link: authLink.concat(httpLink),
        resolvers,
        typeDefs,
      })
    );

    cache.writeData({
      data: {
        quoteId: null,
        coverageAmount: null,
        policyTerms: [],
        products: [],
        applicant: {
          __typename: 'Applicant',
          dob: '',
          email: '',
          fullName: '',
          gender: '',
          phone: '',
          postalCode: '',
          healthReport: {
            __typename: 'HealthReport',
            smoking: null,
          },
        },
        isLoading: false,
      },
    });
  }, [apiKey]);

  useEffect(() => {
    setInterval(() => {
      window.top.postMessage(document.body.scrollHeight, '*');
    }, 10);
  }, []);

  if (client === null) return null;

  return (
    <ApolloProvider client={client}>
      <global.GlobalStyle />

      <Router>
        <LifeInsuranceForm
          onSubmitted={onSubmitted}
          onSubmittedStep={onSubmittedStep}
          trackingData={trackingData}
        />
      </Router>
    </ApolloProvider>
  );
}

App.propTypes = {
  apiKey: PropTypes.string,
  onSubmitted: PropTypes.func,
  onSubmittedStep: PropTypes.func,
  trackingData: PropTypes.shape().isRequired,
};

App.defaultProps = {
  apiKey: undefined,
  onSubmitted: () => {},
  onSubmittedStep: () => {},
};

export default App;
