import styled from 'styled-components';

import { utils, styles, mediaQuery, Button } from '@pav-apps/design-system';

export const ProductStepHeading = styled.h2`
  font-size: ${utils.fluid(33)};
  font-weight: 800;
  line-height: ${(22 / 16) * 1};
  text-align: center;

  color: ${styles.newColors.grey[500]}; /* IE11 fallback */
  color: var(--secondary-text-color);

  max-width: 225px;

  margin: ${utils.fluid(24, 'em')} auto ${utils.fluid(12, 'em')};

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(62)};
    line-height: ${(20 / 16) * 1};

    max-width: 420px;

    margin: ${utils.fluid(16, 'em')} auto ${utils.fluid(8, 'em')};
  }

  ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
    font-size: ${utils.fluid(86)};
    line-height: ${(18 / 16) * 1};

    max-width: 100%;

    margin: 1em 0 ${utils.fluid(8, 'em')};
  }
`;

export const ProductStepDescription = styled.p`
  font-size: ${utils.fluid(18)};
  font-weight: 400;
  line-height: ${(28 / 16) * 1};
  text-align: center;
  color: ${styles.newColors.grey[500]}; /* IE11 fallback */
  color: var(--primary-text-color);

  margin-top: ${utils.fluid(18)};
  margin-bottom: ${utils.fluid(18)};
`;

export const StickyButton = styled(Button)`
  && {
    min-width: 100%;
    border-radius: 0;
    transform: scale(1);
    letter-spacing: 1px;
  }
`;

export const StickyContainer = styled.div`
  position: fixed;
  bottom: -${67 * 1.1 * 1}px;
  width: 100%;
  z-index: 1000;

  transition-property: bottom;
  transition-duration: ${styles.transition.speed.default};
  transition-timing-function: ${styles.transition.easing.inQuint};

  will-change: bottom;

  &.sticky-enter-done {
    bottom: 0;
  }

  ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
    && {
      bottom: -${67 * 1.1 * 1}px;
      transition-timing-function: ${styles.transition.easing.outQuint};
    }
  }
`;

export const ProductList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${utils.fluid(25)};
`;
