import styled, { css } from 'styled-components';

import { styles, utils, mediaQuery } from '@pav-apps/design-system';

export const ProductPrice = styled.span`
  font-size: ${utils.fluid(64)};
  font-weight: 700;
  color: ${props =>
    props.primary ? styles.newColors.primary[500] : styles.newColors.grey[500]};

  ${mediaQuery.lessThan(styles.breakpoints.xs)} {
    font-size: ${utils.fluid(42)};
    color: ${styles.newColors.grey[500]};
  }

  ${props =>
    !props.detailed &&
    css`
      ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
        color: ${styles.colors.white};
      }

      ${mediaQuery.greaterThan(styles.breakpoints.md)} {
        font-size: ${utils.fluid(81)};
      }
    `}
`;

export const ProductPriceSup = styled.sup`
  top: -22px;
  font-size: ${utils.fluid(36)};
  font-weight: 700;
  color: ${props =>
    props.primary ? styles.newColors.primary[500] : styles.newColors.grey[500]};

  ${mediaQuery.lessThan(styles.breakpoints.xs)} {
    top: -15px;
    font-size: ${utils.fluid(24)};
    color: ${styles.newColors.grey[500]};
  }

  ${props =>
    !props.detailed &&
    css`
      ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
        color: ${styles.colors.white};
      }

      ${mediaQuery.greaterThan(styles.breakpoints.md)} {
        top: -28px;
        font-size: ${utils.fluid(45)};
      }
    `}
`;

export const ProductPeriod = styled.span`
  position: absolute;
  right: 0;
  bottom: 6px;
  font-size: ${utils.fluid(20)};
  font-weight: 700;
  color: ${props =>
    props.primary ? styles.newColors.primary[500] : styles.newColors.grey[500]};

  ${mediaQuery.lessThan(styles.breakpoints.xs)} {
    bottom: 5px;
    font-size: ${utils.fluid(14)};
    color: ${styles.newColors.grey[500]};
  }

  ${props =>
    props.detailed
      ? css`
          ${mediaQuery.lessThan(styles.breakpoints.xs)} {
            bottom: 5px;
            font-size: ${utils.fluid(14)};
          }

          ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
            right: -14px;
            bottom: 2px;
            font-size: ${utils.fluid(25)};
          }
        `
      : css`
          ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
            color: ${styles.colors.white};
          }

          ${mediaQuery.greaterThan(styles.breakpoints.md)} {
            right: -4px;
            font-size: ${utils.fluid(28)};
          }
        `}
`;
