import styled from 'styled-components';

import { styles, mediaQuery, Button } from '@pav-apps/design-system';

export const DesktopOnly = styled.div`
  position: relative;
  ${mediaQuery.lessThan(styles.breakpoints.xs)} {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  position: relative;
  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    display: none;
  }
`;

export const StickyButton = styled(Button)`
  && {
    min-width: 100%;
    border-radius: 0;
    transform: scale(1);
    letter-spacing: 1px;
  }
`;

export const StickyContainer = styled.div`
  position: fixed;
  bottom: -${67 * 1.1 * 1}px;
  width: 100%;
  z-index: 1000;

  transition-property: bottom;
  transition-duration: ${styles.transition.speed.default};
  transition-timing-function: ${styles.transition.easing.inQuint};

  will-change: bottom;

  &.sticky-enter-done {
    bottom: 0;
  }

  ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
    && {
      bottom: -${67 * 1.1 * 1}px;
      transition-timing-function: ${styles.transition.easing.outQuint};
    }
  }
`;
