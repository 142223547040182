import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import 'styled-components/macro';
import {
  utils,
  styles,
  Paper,
  RadioButton,
  radioButtonStyles,
} from '@pav-apps/design-system';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { normalizeNumberToString } from '../../../../utils';

const CoverageStepTextFrom = styled.div`
  font-size: ${utils.fluid(14)};
  font-weight: 300;
  text-transform: uppercase;
  color: ${styles.newColors.grey[500]};
`;

const CoverageStepPrice = styled.span`
  font-size: ${utils.fluid(35)};
  font-weight: 700;
  color: ${styles.newColors.grey[500]};

  grid-area: price;
  justify-self: flex-end;
`;

const CoverageStepPriceSup = styled.sup`
  top: 13px;
  font-size: ${utils.fluid(21)};
  font-weight: 700;
  color: ${styles.newColors.grey[500]};

  grid-area: sup;
  justify-self: flex-start;
`;

const CoverageStepTerm = styled.span`
  font-size: ${utils.fluid(14)};
  font-weight: 600;
  color: ${styles.newColors.grey[400]};

  margin-left: 2px;

  grid-area: term;
  align-self: flex-end;
  justify-self: flex-start;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 1em 0;
  background-color: ${styles.newColors.grey[400]};
`;

const PolicyCard = ({ label, value, amount, checked, onBlur, onChange }) => {
  const amountInteger = Math.floor(amount);
  const amountDecimal = normalizeNumberToString(
    Math.round((amount - amountInteger) * 100),
    2
  );

  const isMobile = useMediaQuery(
    `(max-width: ${styles.breakpoints.xs - 0.02}px)`
  );

  return (
    <Paper
      shadowed={isMobile}
      css={`
        border-right: ${isMobile
          ? `0`
          : `2px solid ${styles.newColors.grey[200]}`};
      `}
    >
      <div
        css={`
          display: flex;
          flex-direction: column;
          padding: 0 ${utils.fluid(24, 'em')};

          ${isMobile &&
            css`
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: ${utils.fluid(24, 'em')};
              border-right: 0;
            `}
        `}
      >
        <div
          css={`
            ${isMobile &&
              css`
                flex: 1 1;
                text-align: left;
              `}
          `}
        >
          <RadioButton
            name='policyLength'
            value={value}
            checked={checked}
            onChange={onChange}
            onBlur={onBlur}
            css={`
              ${!isMobile &&
                css`
                  ${radioButtonStyles.upPosition}
                `}
            `}
          >
            {label}
          </RadioButton>
        </div>

        {!isMobile && <Separator />}

        <div>
          {!isMobile && (
            <CoverageStepTextFrom>À partir de</CoverageStepTextFrom>
          )}
          <div
            css={`
              position: relative;
              line-height: 1.2;

              display: grid;
              grid-template-areas:
                'price sup'
                'price term';
            `}
          >
            <CoverageStepPrice>{amountInteger}</CoverageStepPrice>
            <CoverageStepPriceSup>{`,${amountDecimal}$`}</CoverageStepPriceSup>
            <CoverageStepTerm>/ mois</CoverageStepTerm>
          </div>
        </div>
      </div>
    </Paper>
  );
};

PolicyCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  checked: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

PolicyCard.defaultProps = {
  checked: false,
  onBlur: () => {},
  onChange: () => {},
};

export default PolicyCard;
