import React from 'react';
import 'styled-components/macro';

import { Loader, utils } from '@pav-apps/design-system';

const PageLoader = () => (
  <div
    css={`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ${utils.flexCentered}
      background-color: rgba(255,255,255, 0.76);
      z-index: 10000;
    `}
  >
    <Loader />
  </div>
);

export default PageLoader;
