// vendors
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useMutation, useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { css } from 'styled-components';
import 'styled-components/macro';

import {
  mediaQuery,
  styles,
  utils,
  Paper,
  Slider,
  sliderStyles,
} from '@pav-apps/design-system';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useDebounce from '../../../../hooks/useDebounce';

import {
  CoverageStepCardAmount,
  CoverageStepCardHeading,
  CoverageStepCardInfo,
} from './CoverageStep.styles';

import { compactFormat, format } from './CoverageStep.utils';
import { marksAmounts } from '../../../../utils';

const UPDATE_COVERAGE_AMOUNT = gql`
  mutation updateQuote($id: ID!, $quote: LifeInsuranceQuoteInput!) {
    updateLifeInsuranceQuote(id: $id, quote: $quote) {
      success
      message
      session {
        id
        token
        TTL
      }
      quote {
        policyTerms {
          id
          name
          lowerPrice
          selected
        }
        products {
          id
          paymentAmount
          selected
          company {
            name
            logo
          }
          policyTerm {
            id
          }
        }
      }
    }
  }
`;

const AmountSection = ({ onContentLoadingStart, onContentLoadingEnd }) => {
  const { values, setFieldValue } = useFormikContext();

  const client = useApolloClient();

  const isMobile = useMediaQuery(
    `(max-width: ${styles.breakpoints.xs - 0.02}px)`
  );

  const [updateCoverageAmount] = useMutation(UPDATE_COVERAGE_AMOUNT, {
    update(cache) {
      cache.writeData({
        data: {
          coverageAmount: values.coverageAmount,
          policyTerms: [],
          products: [],
        },
      });
    },
    onCompleted(result) {
      const { updateLifeInsuranceQuote: data } = result;

      localStorage.setItem('session_token_ttl', data.session.TTL);

      client.writeData({
        data: {
          products: data.quote.products || [],
          policyTerms: data.quote.policyTerms || [],
          isLoading: false,
        },
      });
    },
  });

  const handleUpdateCoverageAmount = async amount => {
    const id = localStorage.getItem('session_quote_id');

    onContentLoadingStart();

    await updateCoverageAmount({
      variables: {
        id,
        quote: { coverageAmount: amount },
      },
    });

    onContentLoadingEnd();
  };

  const handleCoverageAmountChange = val => {
    setFieldValue('coverageAmount', marksAmounts[val - 1]);
  };

  // Use useRef as a workaround so the linter understands that
  // the referenced values won’t change and avoid infinite loop.
  // See more:
  // - https://github.com/facebook/react/issues/14920
  // - https://blog.logrocket.com/rethinking-hooks-memoization/
  const nonChangeValuesRef = useRef({
    updateCoverageAmountHandler: handleUpdateCoverageAmount,
  });

  const currentAmountIndex = marksAmounts.indexOf(values.coverageAmount);

  const isInitialMount = useRef(true);
  const debouncedVal = useDebounce(values.coverageAmount, 1000);

  useEffect(() => {
    const { updateCoverageAmountHandler } = nonChangeValuesRef.current;
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (debouncedVal) {
      updateCoverageAmountHandler(debouncedVal);
    }
  }, [debouncedVal]);

  return (
    <Paper
      shadowed
      css={`
        ${global.boxSizingReset}
        min-height: ${isMobile ? `0` : `400px`};
        margin: ${isMobile ? `0 ${utils.fluid(20, 'em')}` : `0`};
      `}
    >
      <div
        css={`
          display: flex;
          flex-direction: column;
          text-align: center;
          padding: ${isMobile ? `1em` : `1.5em`};
        `}
      >
        {!isMobile && (
          <div
            css={`
              ${utils.flexCentered}
              flex-direction: column;
            `}
          >
            <CoverageStepCardHeading>
              Montant de la couverture
            </CoverageStepCardHeading>

            <CoverageStepCardInfo>
              La plupart des planificateurs financiers suggèrent 10 x (jusqu’à
              15 x) votre revenu.
            </CoverageStepCardInfo>
          </div>
        )}

        <CoverageStepCardAmount>
          {`${format(values.coverageAmount)} $`}
        </CoverageStepCardAmount>

        <Slider
          name='coverageAmount'
          min={1}
          max={marksAmounts.length}
          value={currentAmountIndex + 1}
          tooltipContent={() => `${format(marksAmounts[currentAmountIndex])}$`}
          getValueText={() => marksAmounts[currentAmountIndex]}
          onChange={handleCoverageAmountChange}
          css={`
            ${sliderStyles.fullWidth}
            align-self: center;

            ${isMobile &&
              css`
                padding-left: 5px;
                padding-right: 5px;
              `}
          `}
        />

        <div
          css={`
            display: grid;
            grid-template-columns: auto auto;
            justify-content: space-between;
            margin: 0 ${utils.fluid(8, 'em')} ${utils.fluid(8, 'em')};

            ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
              margin-bottom: 0;
            }
          `}
        >
          <span>{`${compactFormat(50000)}$`}</span>

          <span>{`${compactFormat(2000000)}$`}</span>
        </div>
      </div>
    </Paper>
  );
};

AmountSection.propTypes = {
  onContentLoadingStart: PropTypes.func,
  onContentLoadingEnd: PropTypes.func,
};

AmountSection.defaultProps = {
  onContentLoadingStart: () => {},
  onContentLoadingEnd: () => {},
};

export default AmountSection;
