import React from 'react';
import styled from 'styled-components';
import { Button, mediaQuery, utils, styles } from '@pav-apps/design-system';

import ApplicationStepConfidential from './ApplicationStepConfidential';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-items: center;
  padding-top: ${utils.fluid(16, 'em')};
  padding-bottom: ${utils.fluid(40, 'em')};

  ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
    padding-top: ${utils.fluid(32, 'em')};
  }
`;

const Info = styled.p`
  font-size: ${utils.fluid(17)};
  font-weight: 300;
  color: ${styles.newColors.grey[400]};

  margin-top: ${utils.fluid(12, 'em')};
  margin-bottom: ${utils.fluid(24, 'em')};
`;

const ApplicantStepBottom = () => (
  <Container>
    <Info>2 minutes avant la ligne d&#39;arrivée!</Info>
    <Button type='submit'>Obtenir mes soumissions</Button>
    <ApplicationStepConfidential />
  </Container>
);

export default ApplicantStepBottom;
