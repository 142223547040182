// vendors
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

// components
import { newColors } from '@pav-apps/design-system/dist/shared/styles';
import App from './components/App';

// utils
import * as serviceWorker from './serviceWorker';

// images
import logoSrc from './images/logo-picki.svg';

const Logo = styled.img`
  display: block;
  width: 100%;
`;

const LogoLink = styled.a`
  display: block;
  margin: auto;
  padding: 1em 0 6em;
  max-width: 120px;
`;

const StyledWrapper = styled.div`
  background-color: ${newColors.grey[100]};
`;

const params = new URLSearchParams(document.location.search.substring(1));

let pickiApiKey = process.env.REACT_APP_PICKI_API_KEY || undefined;

if (params.has('api_key')) [pickiApiKey] = params.getAll('api_key');

const Wrapper = () => (
  <StyledWrapper>
    <App apiKey={pickiApiKey} />

    <LogoLink href='https://picki.ca'>
      <Logo src={logoSrc} />
    </LogoLink>
  </StyledWrapper>
);

ReactDOM.render(<Wrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
