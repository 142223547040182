// vendors
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hideVisually } from 'polished';
import { withApollo, useMutation, useApolloClient } from 'react-apollo';
import 'styled-components/macro';
import gql from 'graphql-tag';

import {
  Stepper,
  Step,
  StepButton,
  Container,
  styles,
  mediaQuery,
  utils,
} from '@pav-apps/design-system';

import {
  Route,
  useNavigate,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';

import ClientOnly from '../../shared/ClientOnly';

import ApplicantStep from './steps/ApplicantStep/ApplicantStep';
import CoverageStep from './steps/CoverageStep/CoverageStep';
import ProductStep from './steps/ProductStep/ProductStep';
import Summary from './steps/Summary/Summary';
import PageLoader from './PageLoader';

const defaultTextStyle = {
  position: 'absolute',
  top: '-30px',
  fontSize: '17px',
  textTransform: 'uppercase',
};

const GET_QUOTE_DATA = gql`
  mutation($id: ID!) {
    updateLifeInsuranceQuote(id: $id) {
      success
      message
      session {
        id
        token
        TTL
      }
      quote {
        id
        coverageAmount
        paymentMode
        policyTerms {
          id
          name
          lowerPrice
          selected
        }
        products {
          id
          paymentAmount
          selected
          company {
            name
            logo
          }
          policyTerm {
            id
          }
        }
        applicant {
          dob
          email
          fullName
          gender
          phone
          postalCode
          healthReport {
            smoking
          }
        }
      }
    }
  }
`;

const steps = [
  {
    value: 'Vous',
    path: '/',
  },
  {
    value: 'Couverture',
    path: '/couverture',
  },
  {
    value: 'Voilà!',
    path: '/resume',
  },
];

const stepsForNavigation = [
  {
    value: 'Vous',
    path: `/`,
  },
  {
    value: 'Couverture',
    path: `/couverture`,
  },
  {
    value: 'Produits',
    path: `/produits`,
  },
  {
    value: 'Voilà!',
    path: `/resume`,
  },
];

const LifeInsuranceForm = ({ onSubmitted, onSubmittedStep, trackingData }) => {
  const [actualStep, setActualStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [blocked, setBlocked] = useState(true);
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();

  const [getQuote] = useMutation(GET_QUOTE_DATA, {
    onCompleted(result) {
      const { updateLifeInsuranceQuote: data } = result;

      localStorage.setItem('session_token_ttl', data.session.TTL);

      client.writeData({
        data: {
          products: data.quote.products,
          paymentMode: data.quote.paymentMode,
          coverageAmount: data.quote.coverageAmount,
          policyTerms: data.quote.policyTerms || [],
          quoteId: data.quote.id,
          applicant: data.quote.applicant,
          isLoading: false,
        },
      });
    },
  });

  useEffect(() => {
    const sessionTokenTTL = localStorage.getItem('session_token_ttl');

    async function fetchData() {
      const quoteId = localStorage.getItem('session_quote_id');

      await getQuote({ variables: { id: quoteId } });

      setLoading(false);
      setBlocked(false);
    }

    if (Number(sessionTokenTTL) > Number(Date.now())) {
      setLoading(true);

      if (location.pathname) {
        const index = stepsForNavigation.findIndex(
          ({ path }) => path === location.pathname
        );

        if (index < 0) {
          navigate('/', { replace: true });
        } else {
          setActualStep(index);
        }
      }

      fetchData();
    } else {
      // TODO: Prompt renew modal
      setBlocked(false);

      localStorage.removeItem('session_token_ttl');
      localStorage.removeItem('session_quote_id');
      localStorage.removeItem('session_id');
      localStorage.removeItem('session_token');

      client.cache.reset();

      setActualStep(0);

      navigate('/', { replace: true });
    }
  }, [actualStep, client, getQuote, location.pathname, navigate]);

  const handleChangeStep = step => {
    setActualStep(step);

    navigate(stepsForNavigation[step].path);
  };

  const handleLoadingStart = () => {
    setTimeout(() => {
      setLoading(true);
    }, 300);
  };

  const handleLoadingEnd = () => {
    setLoading(false);
  };

  return (
    <div
      css={`
        min-height: 1024px;
        background-color: #ececec; /* IE11 fallback */
        background-color: var(--background-color);
      `}
    >
      {loading && <PageLoader />}

      <Container
        maxWidth='xs'
        css={`
          padding: 0 ${utils.fluid(48, 'em')};
          padding-top: ${utils.fluid(64, 'em')};

          ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
            padding-left: 0;
            padding-right: 0;
          }
        `}
      >
        <ClientOnly>
          <Stepper actualStep={actualStep}>
            {steps.map((step, index) => (
              <Step key={step.value} fillColor={styles.newColors.primary[500]}>
                {({
                  active,
                  completed,
                  disabled,
                  width,
                  height,
                  fillColor,
                }) => (
                  <StepButton
                    index={index}
                    active={active}
                    completed={completed}
                    disabled={disabled}
                    onClick={() => {
                      handleChangeStep(index);
                    }}
                    height={height}
                    width={width}
                    fillColor={fillColor}
                    textColor={styles.newColors.grey[400]}
                  >
                    <div
                      css={`
                        ${defaultTextStyle}

                        ${mediaQuery.lessThan(styles.breakpoints.xs)} {
                          ${!active ? hideVisually() : defaultTextStyle}
                        }
                      `}
                    >
                      <span>{step.value}</span>
                    </div>
                  </StepButton>
                )}
              </Step>
            ))}
          </Stepper>
        </ClientOnly>
      </Container>

      {!blocked && (
        <Routes>
          <Route exact path='/'>
            <ApplicantStep
              onLoadingStart={handleLoadingStart}
              onLoadingEnd={handleLoadingEnd}
              trackingData={trackingData}
              onFinish={data => {
                handleChangeStep(1);
                onSubmittedStep(1, data);
              }}
            />
          </Route>

          <Route path='/couverture'>
            <CoverageStep
              onLoadingStart={handleLoadingStart}
              onLoadingEnd={handleLoadingEnd}
              onFinish={data => {
                handleChangeStep(2);
                onSubmittedStep(2, data);
              }}
            />
          </Route>

          <Route path='/produits'>
            <ProductStep
              onLoadingStart={handleLoadingStart}
              onLoadingEnd={handleLoadingEnd}
              onFinish={data => {
                handleChangeStep(3);
                onSubmittedStep(3, data);
                onSubmitted(data);
              }}
            />
          </Route>

          <Route path='/resume'>
            <Summary />
          </Route>

          <Navigate to='/' replace />

          <Route>
            <ApplicantStep
              onLoadingStart={handleLoadingStart}
              onLoadingEnd={handleLoadingEnd}
              onFinish={data => {
                handleChangeStep(1);
                onSubmittedStep(1, data);
              }}
            />
          </Route>
        </Routes>
      )}
    </div>
  );
};

LifeInsuranceForm.propTypes = {
  onSubmitted: PropTypes.func,
  onSubmittedStep: PropTypes.func,
  trackingData: PropTypes.shape().isRequired,
};

LifeInsuranceForm.defaultProps = {
  onSubmitted: () => {},
  onSubmittedStep: () => {},
};

export default withApollo(LifeInsuranceForm);
