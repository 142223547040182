import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import 'styled-components/macro';
import { useFormikContext } from 'formik';
import {
  global,
  styles,
  utils,
  Paper,
  RadioButton,
  mediaQuery,
} from '@pav-apps/design-system';

import {
  ProductPrice,
  ProductPriceSup,
  ProductPeriod,
} from './ProductCard.styles';

import { ChoosePlanButton } from './shared/styles';
import { normalizeNumberToString } from '../../../../utils';

// TODO: Replace CSS Flexbox with Grid for a more lightweight layout.
const ProductCard = ({
  name,
  value,
  checked,
  primary,
  amount,
  term,
  period,
  logo,
  onChange,
  onBlur,
  detailed,
}) => {
  const { setFieldValue } = useFormikContext();
  const amountInteger = Math.floor(amount);
  const amountDecimal = normalizeNumberToString(
    Math.round((amount - amountInteger) * 100),
    2
  );

  const Logo = () => (
    <picture
      css={`
        ${utils.flexCentered}
        flex: 1 1;

        min-width: 80px;

        /* For Webkit */
        > * {
          height: auto;
          width: 100%;
        }
      `}
    >
      {logo.srcsetwebp && <source type='image/webp' srcSet={logo.srcsetwebp} />}

      {logo.srcset && <source type='image/jpeg' srcSet={logo.srcset} />}

      <img src={logo.src} alt={logo.alt} />
    </picture>
  );

  const handleClick = () => {
    setFieldValue('productSelected[id]', value);

    const policyTerm = term.split(':');
    setFieldValue('productSelected[type]', policyTerm[0]);
    setFieldValue('productSelected[term]', policyTerm[1]);
  };

  return (
    <div>
      <Paper shadowed>
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;

            padding: ${utils.fluid(16, 'em')};

            ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
              padding: ${utils.fluid(32, 'em')};
            }

            ${detailed
              ? css`
                  ${mediaQuery.greaterThan(styles.breakpoints.md)} {
                    padding: ${utils.fluid(32, 'em')} ${utils.fluid(64, 'em')};
                  }
                `
              : css`
                  ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
                    padding: 0;
                    flex-direction: column;
                  }
                `}
          `}
        >
          <div
            css={`
              display: inline-flex;
              align-items: center;
              justify-content: flex-start;

              flex: 1 1;

              ${!detailed && global.fullWidth}

              ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
                justify-content: center;

                ${!detailed &&
                  css`
                    min-height: 218px;
                    padding: ${utils.fluid(24, 'em')};
                    box-sizing: border-box;
                  `};
              }
            `}
          >
            <RadioButton
              name={name}
              value={value}
              checked={checked}
              onChange={onChange}
              onBlur={onBlur}
              css={`
                flex: 1 1;

                ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
                  display: none;
                }
              `}
            >
              <Logo />
            </RadioButton>

            <div
              css={`
                flex: 1 1;

                ${mediaQuery.lessThan(styles.breakpoints.sm)} {
                  display: none;
                }
              `}
            >
              <Logo />
            </div>
          </div>

          <div
            css={`
              display: inline-flex;
              align-items: center;
              justify-content: flex-end;

              flex: 1 1;

              ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
                justify-content: center;
                flex: 2 1;
              }

              ${!detailed &&
                css`
                  ${global.fullWidth}
                  ${global.boxSizingReset}

                  ${mediaQuery.greaterThan(styles.breakpoints.sm)} {
                    padding: ${utils.fluid(32, 'em')};
                    justify-content: center;
                    background-color: ${styles.newColors.primary[500]};
                  }
              `}
            `}
          >
            <div
              css={`
                position: relative;
                line-height: 1.2;
              `}
            >
              <ProductPrice primary={primary} detailed={detailed}>
                {amountInteger}
              </ProductPrice>

              <ProductPriceSup primary={primary} detailed={detailed}>
                {`,${amountDecimal}$`}
              </ProductPriceSup>

              <ProductPeriod primary={primary} detailed={detailed}>
                {`/ ${period}`}
              </ProductPeriod>
            </div>
          </div>

          {detailed && (
            <ChoosePlanButton
              tag='button'
              onClick={handleClick}
              type='submit'
              css={`
                flex: 1 1;

                && {
                  margin-top: 0;
                }
              `}
            >
              Choisir cette offre
            </ChoosePlanButton>
          )}
        </div>
      </Paper>

      {!detailed && (
        <ChoosePlanButton
          tag='button'
          onClick={handleClick}
          type='submit'
          css={`
            ${global.fullWidth}
          `}
        >
          Choisir cette offre
        </ChoosePlanButton>
      )}
    </div>
  );
};

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  detailed: PropTypes.bool,
  primary: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  logo: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcset: PropTypes.string,
    srcsetwebp: PropTypes.string,
    alt: PropTypes.string.isRequired,
  }).isRequired,
};

ProductCard.defaultProps = {
  checked: false,
  detailed: false,
  primary: false,
  onChange: () => {},
  onBlur: () => {},
};

export default ProductCard;
