// vendors
import React from 'react';
import { useFormikContext } from 'formik';
import {
  FormHelperText,
  Paper,
  Select,
  SelectOption,
  Tooltip,
  global,
  icons,
  mediaQuery,
  utils,
  styles,
  selectStyles,
} from '@pav-apps/design-system';
import {} from 'styled-components/macro';

const ApplicantStepDOB = () => {
  const normalizeNumber = number => (number < 10 ? `0${number}` : number);

  const fillSelectPlaceholder = text => [
    <SelectOption key={text} disabled hidden value=''>
      {text}
    </SelectOption>,
  ];

  const fillSelectOptionsTo = maxVal => {
    return Array(maxVal)
      .fill()
      .map((_, index) => {
        return (
          <SelectOption
            key={String(index + 1)}
            value={normalizeNumber(index + 1)}
          >
            {normalizeNumber(index + 1)}
          </SelectOption>
        );
      });
  };

  const fillYearsFrom = year => {
    const fromDate = new Date(year.toString()).getFullYear() + 1;
    const toDate = new Date().getFullYear();
    // eslint-disable-next-line prefer-spread
    return Array.apply(null, { length: toDate + 1 - fromDate }).map(
      (_, index) => (
        <SelectOption key={String(toDate - index)} value={toDate - index}>
          {toDate - index}
        </SelectOption>
      )
    );
  };

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormikContext();

  const isError =
    (errors.dobDay && touched.dobDay) ||
    (errors.dobMonth && touched.dobMonth) ||
    (errors.dobYear && touched.dobYear);

  const ref = React.createRef();

  const defaultTooltip = (
    <p
      css={`
        margin: 0;
      `}
    >
      Le coût de l’assurance vie est établi,
      <br />
      notamment, en fonction de votre âge.
    </p>
  );

  return (
    <Paper padded bordered shadowed>
      <Tooltip
        label={defaultTooltip}
        ariaLabel='Le coût de l’assurance vie est établi, notamment, en fonction de votre âge.'
      >
        <div
          ref={ref}
          css={`
            position: absolute;
            top: 32px;
            right: 27px;

            ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
              top: 27px;
            }
          `}
        >
          <icons.QuestionMark
            css={`
              height: 20px;
              width: 20px;
              fill: ${styles.newColors.grey[400]};

              ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
                height: 30px;
                width: 30px;
              }
            `}
          />
        </div>
      </Tooltip>

      <fieldset
        css={`
          ${global.fullWidth}
          ${global.resetDefault}
        `}
      >
        <legend
          css={`
            margin-bottom: ${utils.fluid(32)};
            font-size: ${utils.fluid(21)};
            font-weight: 600;
            color: ${styles.newColors.grey[500]};
          `}
        >
          Date de naissance
        </legend>

        <div
          css={`
            ${global.fullWidth}

            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-column-gap: ${utils.fluid(14)};

            ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
              grid-template-columns: repeat(3, 1fr);
            }
          `}
        >
          <Select
            value={values.dobDay}
            name='dobDay'
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.dobDay && touched.dobDay}
            css={`
              ${global.fullWidth}
              ${selectStyles.centered}

              ${mediaQuery.lessThan(styles.breakpoints.xs)} {
                ${selectStyles.smaller}
              }

              ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
                ${selectStyles.bigger}
              }
            `}
          >
            {fillSelectPlaceholder('JJ').concat(fillSelectOptionsTo(31))}
          </Select>

          <Select
            value={values.dobMonth}
            name='dobMonth'
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.dobMonth && touched.dobMonth}
            css={`
              ${global.fullWidth}
              ${selectStyles.centered}

              ${mediaQuery.lessThan(styles.breakpoints.xs)} {
                ${selectStyles.smaller}
              }

              ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
                ${selectStyles.bigger}
              }
            `}
          >
            {fillSelectPlaceholder('MM').concat(fillSelectOptionsTo(12))}
          </Select>

          <Select
            value={values.dobYear}
            name='dobYear'
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.dobYear && touched.dobYear}
            css={`
              ${global.fullWidth}
              ${selectStyles.centered}
              
              ${mediaQuery.lessThan(styles.breakpoints.xs)} {
                ${selectStyles.smaller}
              }

              ${mediaQuery.greaterThan(styles.breakpoints.xs)} {
                ${selectStyles.bigger}
              }
            `}
          >
            {fillSelectPlaceholder('AAAA').concat(fillYearsFrom(1905))}
          </Select>

          {isError && (
            <FormHelperText
              id='dob-helper-text'
              error={isError}
              css={`
                grid-column: span 3;
              `}
            >
              {errors.dobDay || errors.dobMonth || errors.dobYear}
            </FormHelperText>
          )}
        </div>
      </fieldset>
    </Paper>
  );
};

export default ApplicantStepDOB;
