import gql from 'graphql-tag';

export const resolvers = {};

export const typeDefs = gql`
  extend type Query {
    quoteId: ID
    coverageAmount: Int
    policyTerms: [Term]!
    products: [LifeInsuranceProduct]!
    applicant: Applicant
    isLoading: Boolean
    paymentMode: PaymentOptions
  }
`;
